import { Link } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import * as styles from '../styles/404.module.scss'

export default () => {
  return (
    <Layout>
        <div className={styles.container}>
            <h1>Page not found...</h1>
            <h2>Oops! The page you are looking for has been removed, relocated or never existed in the first place.</h2>
            <Link to={'/'}>Go to Homepage</Link>
        </div>
    </Layout>
  );
};
